@font-face {
    font-family: 'Rubik-Regular';
    src: url('fonts/Rubik-Regular.ttf');
}
@font-face {
    font-family: 'Rubik-Medium';
    src: url('fonts/Rubik-Medium.ttf');
}
@font-face {
    font-family: 'Rubik-Light';
    src: url('fonts/Rubik-Light.ttf');
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('fonts/Roboto-Medium.ttf');
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Rubik-Regular', sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.box-header, .sa-box-header{
    font-family: "Rubik-Medium";
    font-weight: 500;
}

.plugin {
    &-error,
    &-loading {
        display: flex;
        justify-content: center;
        padding: 20px;
        height: 45px;
    }

    .contact-info{
        padding: 20px;
        display: flex;

        .label{
            margin-bottom: 4px;
            font-weight: 500;
            font-family: "Rubik-Medium";
        }

        span{
            line-height: 20px;
        }

        .info-block{
            display: flex;
            flex-direction: column;
            flex: 1;

            .data-block{
                margin-bottom: 10px;
            }

            .c4c{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 30px;
                border-radius: 4px;
                background: #3B83BF;
                border: none;
                color: white;
                font-family: "Rubik-Medium";
                font-weight: 500;
                cursor: pointer;
                text-decoration: none;
            }

            .copy-button{
                width: 25px;
                height: 25px;
                &:hover, &:focus{
                    span{
                        content: url(../images/copy-icon-fill.svg);
                    }
                }

                span{
                    content: url(../images/copy-icon-outline.svg);
                    width: 16px;
                    height: 16px;
                 }
                margin-left: 5px;
                cursor: pointer;
                border: none;
                background: none; 
                padding: 0 5px;
            }
        }
    }
}

.row {
    display: flex;
    width: 100%;

    &.column {
        flex-direction: column;
    }
    &.between {
        justify-content: space-between;
    }
    &.center {
        justify-content: center;
    }
    &.middle {
        align-items: center;
    }
    &.start {
        align-items: flex-start;
    }
    &.end {
        justify-content: flex-end;
    }
    &.grow {
        > * {
            flex-grow: 1;
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
    &.bottom {
        align-items: flex-end;
    }
}